<template>
	<div class="container-fluid product-overview-bg pb-2">
		<div class="container">
			<div class="pt-3 pb-lg-1">
				<slot name="breadcrumbs" />
				<h1 v-if="vm.gridViewTitle" class="mb-2">{{ vm.gridViewTitle }}</h1>
				<p class="lead">{{ vm.subtitle }}</p>
				<hr class="my-1 my-lg-2" />
			</div>

			<DataView
				class="w-100"
				:layout="viewLayout"
				:lazy="true"
				:paginator="true"
				:rows="filterStore.filter.pageSize"
				:total-records="filterStore.filter.totalItems"
				:value="filterStore.overviewItems"
				@page="onPageChange"
			>
				<template #header>
					<div class="d-flex flex-wrap justify-content-between flex-1">
						<div class="header-left justify-content-start justify-content-lg-start">
							<template v-for="(filter, index) in activeFilters" :key="index">
								<MultiSelect
									v-model="filterStore.filter[filter.model]"
									append-to="self"
									class="flex-grow-1 flex-lg-grow-0"
									option-value="value"
									:options="filter.tags"
									:placeholder="filter.placeholder"
									scroll-height="400px"
									:show-toggle-all="false"
									@change="filterStore.updateFilters()"
								>
									<template #value="slotProps">
										<template v-if="slotProps.value?.length === 1">
											<div>
												<span>
													{{
														filter.tags.find((x) => x.value === slotProps.value[0])
															?.label
													}}
												</span>
											</div>
										</template>

										<div v-else-if="slotProps.value?.length > 1">
											<span>
												{{
													$t("contentCategory.lblOptionSelected", {
														n: slotProps.value?.length,
													})
												}}
											</span>
										</div>
									</template>

									<template #option="slotProps">
										<span>{{ slotProps.option.label }}</span>
									</template>
								</MultiSelect>
							</template>
						</div>

						<div v-if="!isSmallScreen" class="header-right">
							<Dropdown
								v-model="filterStore.sortType"
								option-label="label"
								option-value="value"
								:options="filterStore.sortOptions"
								:show-toggle-all="false"
								@change="filterStore.onSortChange()"
							/>
							<DataViewLayoutOptions v-if="!isMobile" v-model="viewLayout" class="ml-1" />
						</div>
					</div>
				</template>
				<template #empty>
					<Message :closable="false" severity="warn">
						{{ $t("product.message.noOverviewItems") }}
					</Message>
				</template>
				<template #list="item">
					<ContentCategoryOverviewItemListCard
						v-if="!isSpinnerVisible('contentCategory')"
						:item="item.data"
					/>
					<ContentCategoryOverviewItemSkeletonCard v-else type="list" />
				</template>
				<template #grid="item">
					<div class="col-12 col-md-6 col-lg-4 col-xxl-3">
						<ContentCategoryOverviewItemGridCard
							v-if="!isSpinnerVisible('contentCategory')"
							:item="item.data"
						/>
						<ContentCategoryOverviewItemSkeletonCard v-else type="grid" />
					</div>
				</template>
			</DataView>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import ContentCategoryOverviewItemGridCard from "@/components/general/contentCategoryOverviewItemGridCard.vue";
import ContentCategoryOverviewItemListCard from "@/components/general/contentCategoryOverviewItemListCard.vue";
import ContentCategoryOverviewItemSkeletonCard from "@/components/general/contentCategoryOverviewItemSkeletonCard.vue";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import MultiSelect from "primevue/multiselect";
import VirtualScroller from "primevue/virtualscroller";
import { Component, Prop, Watch } from "vue-facing-decorator";
import { ContentCategoryOverviewViewModel } from "@/types/viewModels/general/contentCategoryOverviewViewModel";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { Log } from "@/types/helpers/logHelper";
import { PropType } from "vue";
import { useContentCategoryOverviewFilterStore } from "@/store/common/contentCategoryOverviewFilterStore";

export type OverviewLayoutType = "grid" | "list";

interface activeFilterType {
	placeholder: string;
	model: string;
	tags: IDropdownItem[] | null;
}

@Component({
	components: {
		ContentCategoryOverviewItemGridCard,
		ContentCategoryOverviewItemListCard,
		ContentCategoryOverviewItemSkeletonCard,
		DataView,
		DataViewLayoutOptions,
		MultiSelect,
		VirtualScroller,
	},
})
export default class ContentCategoryOverview extends BaseComponent {
	@Prop({
		type: Object as PropType<ContentCategoryOverviewViewModel>,
		required: true,
		default: {},
	})
	vm!: ContentCategoryOverviewViewModel;
	overviewItems!: unknown[];
	viewLayout!: OverviewLayoutType;

	filterStore = useContentCategoryOverviewFilterStore();
	activeFilters: Array<activeFilterType> = [];

	@Watch("viewLayout") onLayoutChanged(value: OverviewLayoutType): void {
		if (!this.isSmallScreen) sessionStorage.setItem(this.overviewLayoutStorageKey, value);
	}

	created(): void {
		Log.info("ContentCategoryOverview", this.vm);

		this.filterStore.init(this.vm);

		if (this.filterStore.hasAuthorTags) {
			const authorTagFilter = {
				placeholder: this.vm.authorFilterText,
				model: "authorTags",
				tags: this.filterStore.authorTags,
			};
			this.activeFilters.push(authorTagFilter);
		}
		if (this.filterStore.hasThemeTags) {
			const themeTagFilter = {
				placeholder: this.vm.themeFilterText,
				model: "themeTags",
				tags: this.filterStore.themeTags,
			};
			this.activeFilters.push(themeTagFilter);
		}
		if (this.filterStore.hasBranchTags) {
			const branchTagFilter = {
				placeholder: this.vm.branchFilterText,
				model: "branchTags",
				tags: this.filterStore.branchTags,
			};
			this.activeFilters.push(branchTagFilter);
		}
	}

	mounted(): void {
		this.viewLayout = this.isSmallScreen
			? "grid"
			: (sessionStorage.getItem(this.overviewLayoutStorageKey) as OverviewLayoutType) || "list";
	}

	scrollToTop(): void {
		const element = document.querySelector(`.content-list-item`);
		if (!element) return;
		window.scrollTo({
			top: element.getBoundingClientRect().top + this.layoutStore.navBarHeight,
			behavior: "smooth",
		});
	}

	onPageChange({ page }: { page: number }): void {
		this.scrollToTop();
		this.filterStore.onPageChange(page);
	}

	get overviewLayoutStorageKey(): string {
		return `CDC_OverviewLayout_ContentCategories`;
	}
}
</script>

<style scoped lang="scss">
::v-deep(.p-dataview) {
	.p-dataview-header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		background: var(--surface-a);
		border: none !important;
		position: sticky;
		top: 0;
		z-index: 10;
		padding: 0.75rem 0;

		.header-left {
			display: flex;
			flex-wrap: wrap;
			margin: 0.5rem 0;
			gap: 0.5rem;
		}

		.header-right {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.p-dataview-layout-options {
				display: flex;
				flex-direction: row;
			}
		}

		.header-search {
			flex-grow: 1;
		}
	}

	.p-button.p-button-icon-only {
		padding: 0.75rem 0;
	}

	.p-button-outlined {
		.p-badge {
			color: #fff;
		}
	}
}

::v-deep(.p-dataview-grid) {
	.grid {
		display: flex;
		flex-wrap: wrap;

		margin-right: -0.5rem;
		margin-left: -0.5rem;

		//> .col,
		//> [class*="col-"] {
		//	padding: 0;
		//}
	}
}
</style>
