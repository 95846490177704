import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b213218e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cashback-progress-wrapper mb-4 p-4" }
const _hoisted_2 = { class: "wallet-amount-text mb-2" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = { class: "heading-2" }
const _hoisted_5 = { class: "mb-2" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col col-6" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "col col-6 d-flex justify-content-end" }
const _hoisted_10 = ["alt"]
const _hoisted_11 = { class: "wallet-amount-small" }
const _hoisted_12 = {
  key: 0,
  class: "reward-wrapper pb-4"
}
const _hoisted_13 = { class: "reward-name" }
const _hoisted_14 = { class: "reward-price" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  key: 1,
  class: "reward-wrapper pb-4"
}
const _hoisted_17 = { class: "reward-name" }
const _hoisted_18 = { class: "reward-price" }
const _hoisted_19 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_CircularProgress = _resolveComponent("CircularProgress")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("img", {
          alt: _ctx.vm.icon.alt,
          class: "wallet-icon"
        }, null, 8, _hoisted_3), [
          [_directive_lazy, _ctx.vm.icon.url]
        ]),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.vm.headerTitle), 1)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.vm.headerSubtitle), 1),
      _createElementVNode("div", null, [
        _createVNode(_component_Button, {
          class: "p-button-brand-secondary px-3",
          icon: "pi pi-arrow-right ml-2",
          "icon-pos": "right",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openUrl(_ctx.vm.headerButtonLink.url))),
          label: _ctx.vm.headerButtonLink.text
        }, null, 8, ["label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.vm.pageTitle), 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _withDirectives(_createElementVNode("img", {
          alt: _ctx.vm.icon.alt,
          class: "wallet-icon-small"
        }, null, 8, _hoisted_10), [
          [_directive_lazy, _ctx.vm.icon.url]
        ]),
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.formatCurrency(_ctx.vm.totalAutodeskSaved)), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mappedProds, (product) => {
        return (_openBlock(), _createElementBlock("div", {
          key: product,
          class: "col col-12 col-sm-6 col-lg-4"
        }, [
          (product.cashback && product.cashbackText && product.price && product.priceText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_component_CircularProgress, { item: product }, null, 8, ["item"]),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.formatCurrency(product.cashback)) + " " + _toDisplayString(product.cashbackText), 1),
                _createElementVNode("div", _hoisted_14, [
                  (product.price)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(product.priceText) + " " + _toDisplayString(_ctx.formatCurrency(product.price)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_component_Button, {
                  class: "p-button-success p-button-sm",
                  label: product.buttonLink.text,
                  onClick: ($event: any) => (_ctx.openUrl(product.buttonLink.url)),
                  disabled: _ctx.vm.totalAutodeskSaved < product.price
                }, null, 8, ["label", "onClick", "disabled"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createVNode(_component_CircularProgress, { item: product }, null, 8, ["item"]),
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.formatProductName(product.name, product.brand)), 1),
                _createElementVNode("div", _hoisted_18, [
                  (product.price)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.$t('myCadac.myrewards.tile.priceText')) + " " + _toDisplayString(_ctx.formatCurrency(product.price)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_component_Button, {
                  class: "p-button-success p-button-sm",
                  label: _ctx.$t('myCadac.myrewards.button.label'),
                  onClick: ($event: any) => (_ctx.openUrl(product.productPageUrl))
                }, null, 8, ["label", "onClick"])
              ]))
        ]))
      }), 128))
    ])
  ], 64))
}