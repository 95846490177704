<template>
	<div class="container">
		<div class="row mt-2 mt-lg-3 mb-1 ">
			<div class="col-md-12">
				<h1 v-if="this.activeStep === 1">{{$t("quotation.page.title.step1")}}</h1>
				<h1 v-if="this.activeStep === 2">{{$t("quotation.page.title.step2")}}</h1>
				<QuotationSteps :active-step="this.activeStep" />
			</div>
		</div>
		<div v-if="this.activeStep === 1" class="row mb-2 mb-lg-3">
			<div v-if="isSpinnerVisible('loading')"
				 class="loader d-flex flex-column justify-content-center align-items-center text-center mx-auto form-group col-md-12 col-lg-10 col-xxl-8">
				<ProgressSpinner animation-duration="1.75s" stroke-width="4" />
			</div>
			<template v-if="!isSpinnerVisible('loading')">
				<div v-if="!layoutStore.isAuthenticated" class="col-12 col-md-12">
					<div class="d-lg-none d-block">
						<p class="mt-1 mb-3 login-text">{{$t("quotation.login.text")}}</p>
						<a class="text-underline login-button" :href="loginPageUrl">{{$t("quotation.login.button.text")}} <span class="icon"></span></a>
					</div>
					<p class="d-none d-lg-block mt-1 mb-3 login-text">{{$t("quotation.login.text")}} <a class="text-underline" :href="loginPageUrl">Log in <i class="pi pi-chevron-right login-chev"></i></a></p>
				</div>
				<section class="col-12 col-lg-7">
					<form class="position-relative" @change="formStateChanged">

						<template v-if="!isSpinnerVisible('loading')">
							<div class="form-row">
								<div class="form-group col-md-6 col-xxl-5">
									<InputWithValidation v-slot="{ field }"
														 :is-required="validations['firstName'].required"
														 :label="$t('common.forms.lblFirstName')"
														 name="firstName">
										<InputText v-model="field.value.value"
												   :class="{ 'p-invalid': field.errors?.length }"
												   :feedback="false"
												   :name="field.name"
												   toggle-mask
												   :placeholder="$t('common.forms.lblFirstName')"
												   @blur="field.setTouched(true)" />
									</InputWithValidation>
								</div>
								<div class="form-group col-md-6 col-xxl-5">
									<InputWithValidation v-slot="{ field }"
														 :is-required="validations['lastName'].required"
														 :label="$t('common.forms.lblLastName')"
														 name="lastName">
										<InputText v-model="field.value.value"
												   :class="{ 'p-invalid': field.errors?.length }"
												   :feedback="false"
												   :name="field.name"
												   toggle-mask
												   :placeholder="$t('common.forms.lblLastName')"
												   @blur="field.setTouched(true)" />
									</InputWithValidation>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-12 col-xxl-10">
									<InputWithValidation v-slot="{ field }"
														 :is-required="validations['email'].required"
														 :label="$t('common.forms.lblEmail')"
														 name="email">
										<InputText v-model="field.value.value"
												   :class="{ 'p-invalid': field.errors?.length }"
												   :feedback="false"
												   :name="field.name"
												   toggle-mask
												   :placeholder="$t('common.forms.lblEmail')"
												   @blur="field.setTouched(true)" />
									</InputWithValidation>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-12 col-xxl-10">
									<InputWithValidation v-slot="{ field }"
														 :is-required="validations['phone'].required"
														 :label="$t('common.forms.lblPhoneNumber')"
														 name="phone">
										<vue-tel-input v-model="field.value.value"
													   :class="{ 'p-invalid': field.errors?.length }"
													   :input-options="{ placeholder: $t('common.forms.lblPhoneNumber') }"
													   @change="field.setTouched(true)"
													   @validate="onPhoneInputValidate" />
									</InputWithValidation>
								</div>
							</div>

							<div class="form-row">
								<div class="form-group col-md-12 col-xxl-10">
									<InputWithValidation v-slot="{ field }"
														 :is-required="validations['companyName'].required"
														 :label="$t('common.forms.lblCompanyName')"
														 name="companyName">
										<AutoComplete v-model="field.value.value"
													  class="w-100"
													  :class="{ 'p-invalid': field.errors?.length }"
													  :min-length="2"
													  option-label="name"
													  :suggestions="companySuggestions"
													  :placeholder="$t('common.forms.lblCompanyName')"
													  @complete="searchCompanies($event.query)"
													  @item-select="onSuggestionSelection($event.value.dunsNumber)"
													  @change="autoFillOptOut()">
											<template #option="{ option }">
												<div class="d-flex">
													<p class="mb-0 mr-1 text-bold">
														{{ option?.name }}
													</p>
													<p class="mb-0">in {{ option?.mainAddress.city }}</p>
												</div>
											</template>
										</AutoComplete>
									</InputWithValidation>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-12 col-xxl-10">
									<Checkbox v-model="checked2"
											  inputId="wantsMoreProducts"
											  :binary="true"
											  name="wantsMoreProducts"
											  @change="checkboxFieldProducts.handleChange(checkboxFieldProducts.checkedValue)" />
									<label class="ml-1 mb-0" for="wantsMoreProducts">
										{{$t('quotation.field.wantsMoreProducts')}}
									</label>
								</div>
							</div>
							<div class="form-row" v-show="checkboxFieldProducts.checked">
								<div class="form-group col-md-12 col-xxl-10">
									<p class="product-message-note">{{$t('quotation.field.productMessage.note')}}</p>
									<InputWithValidation v-slot="{ field }"
														 :is-required="validations['productMessage'].required"
														 name="productMessage">
										<Textarea v-model="field.value.value"
												  :class="{ 'p-invalid': field.errors?.length, 'message-field': 'message-field' }"
												  rows="4"
												  type="text"
												  :name="field.name"
												  :placeholder="$t('quotation.field.productMessage.placeholder')"
												  @blur="field.setTouched(true)" />
									</InputWithValidation>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-12 col-xxl-10">
									<Checkbox v-model="checked"
											  inputId="hasAutodeskCSN"
											  :binary="true"
											  name="hasCSN"
											  @change="checkboxFieldCSN.handleChange(checkboxFieldCSN.checkedValue)" />
									<label class="ml-1 mb-0" for="hasAutodeskCSN">
										{{$t('quotation.field.hasCSN')}}
									</label>
								</div>
							</div>
							<div class="form-row" v-show="checkboxFieldCSN.checked">
								<div class="form-group col-md-12 col-xxl-10">
									<InputWithValidation v-slot="{ field }"
														 :is-required="validations['message'].required"
														 name="message">
										<Textarea v-model="field.value.value"
												  :class="{ 'p-invalid': field.errors?.length, 'message-field': 'message-field' }"
												  rows="4"
												  type="text"
												  :name="field.name"
												  :placeholder="$t('quotation.field.message.placeholder')"
												  @blur="field.setTouched(true)" />
									</InputWithValidation>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6 col-xxl-5 d-flex justify-content-center">
									<Button class="w-100 p-button-brand-secondary p-button-lg"
											:label="$t('common.cancel')"
											@click="goBack()" />
								</div>
								<div class="form-group col-md-6 col-xxl-5">
									<Button class="w-100 p-button-success p-button-lg"
											:label="$t('quotation.field.submit')"
											:loading="isLoading"
											:disabled="!isValid || isLoading"
											@click="onSubmit" />
								</div>
							</div>
						</template>
					</form>
				</section>
				<section class="col-12 col-lg-5 d-none d-lg-block">
					<div class="product-summary">
						<div class="d-flex justify-content-between flex-wrap-reverse mb-2 mb-sm-3">
							<div class="mr-2">
								<img class="product-summary-icon" :alt="vm?.firstProductSelection?.product?.icon?.altTag" :src="vm?.firstProductSelection?.product?.icon?.url" />
							</div>
							<div class="d-flex flex-column flex-1">
								<p class="mb-0 text-muted lead">{{ vm.firstProductSelection.product.brand }}</p>
								<h4 class="mb-0">{{ vm.firstProductSelection.product.name }}</h4>
							</div>
						</div>
						<div class="d-flex justify-content-between flex-wrap-reverse mb-1 mb-sm-2">
							<div class="col-6 d-flex align-items-center">
								<div class="product-term">{{$t('quotation.product.amount.label')}}</div>
							</div>
							<div class="col-6 d-flex align-items-center">
								<div class="product-detail">{{ vm.firstProductSelection.amount }}</div>
							</div>
						</div>
						<div v-if="vm.firstProductSelection.product.durationInMonths" class="d-flex justify-content-between flex-wrap-reverse mb-1 mb-sm-2">
							<div class="col-6 d-flex align-items-center">
								<div class="product-term">{{$t('quotation.product.duration.label')}}</div>								
							</div>
							<div class="col-6 d-flex align-items-center">								
								<div class="product-detail">{{ $t("quotation.product.duration.months", [vm.firstProductSelection.product.durationInMonths]) }}</div>
							</div>
						</div>
							<div class="d-flex justify-content-between flex-wrap-reverse mb-1 mb-sm-2">
								<div class="col-6 d-flex align-items-center">
									<div class="product-term">{{$t('quotation.product.price.label')}}</div>
								</div>
								<div class="col-6 d-flex align-items-center">
									<div class="product-detail">{{ formatCurrency(vm.firstProductSelection.totalPrice.basePrice) }}</div>
								</div>
							</div>
							<div class="d-flex justify-content-between flex-wrap-reverse">
								<div class="col-12">
									<p class="bottom-text">{{$t('quotation.product.price.subText')}}</p>
								</div>
							</div>
						</div>
					<div :style="{'background-image': `url('/Static/images/stars-background.jpg')`}"
						 class="wallet-card"
						 v-if="vm.autodeskLoyaltyCashbackBlock?.isVisible && parseInt(vm.firstProductSelection?.totalWalletAmount) !== 0"							 
						 >
						<img v-if="vm.autodeskLoyaltyCashbackBlock?.icon" v-lazy="vm.autodeskLoyaltyCashbackBlock?.icon?.url" :alt="vm.autodeskLoyaltyCashbackBlock?.icon?.alt" class="wallet-icon" />
						<div class="wallet-text">							
							{{vm.autodeskLoyaltyCashbackBlock?.text}}
						</div>
						<span class="wallet-amount">{{ formatCurrency(vm.firstProductSelection?.totalWalletAmount) }}</span>
					</div>
					</section>
				</template>
			</div>
			<div v-if="this.activeStep === 2" class="row mb-2 mb-lg-3 thank-you-container">
				<div class="thank-you-content">
					<h1 class="mb-3">{{$t('quotation.thankYou.title')}}</h1>
					<p class="mb-3">
						{{$t('quotation.thankYou.text')}}
					</p>
					<img v-lazy="'/Static/images/support.png'" alt="Cadac Group" class="thank-you-img mb-3" />
					<Button class="w-100 p-button-primary p-button-raised p-button-lg"
							:label="$t('quotation.thankYou.backButton')"
							@click="goBack()" />
				</div>
			</div>
		</div>
		<section v-if="!isSpinnerVisible('loading') && this.activeStep === 1" class="d-block d-lg-none sticky-bottom-panel py-2">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="d-flex align-items-center justify-content-between">
							<div class="d-flex justify-content-between flex-wrap-reverse pr-sm-4 pr-3">
								<div class="mr-sm-2 mr-1">
									<img class="product-summary-icon" :alt="vm?.firstProductSelection?.product?.icon?.altTag" :src="vm?.firstProductSelection?.product?.icon?.url" />
								</div>
								<div class="product-text-wrapper  d-flex flex-column flex-1">
									<p class="mb-0 text-muted lead">{{ vm.firstProductSelection.product.brand }}</p>
									<h4 class="mb-0 title">{{ vm.firstProductSelection.product.name }}</h4>
								</div>
							</div>
							<div class="price-text-wrapper text-right">
								<div class="price-text">{{ formatCurrency(vm.firstProductSelection.totalPrice.basePrice) }}</div>
								<div class="wallet-text">{{$t('quotation.product.wallet.label')}} {{ formatCurrency(vm.firstProductSelection.totalWalletAmount) }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</template>

	<script lang="ts">

	import BaseComponent from "@/components/base/baseComponent.vue";
	import InputWithValidation from "@/components/common/inputWithValidation.vue";
	import { Component, Prop } from "vue-facing-decorator";
	import { FormContext, useForm, FormMeta, useField, FieldContext } from "vee-validate";
	import { computed, reactive, unref } from "vue";
	import Textarea from "primevue/textarea";
	import Checkbox from "primevue/checkbox";
	import { PropType } from "vue";
	import { Log } from "@/types/helpers/logHelper";
	import AutoComplete from "primevue/autocomplete";
	import ProgressSpinner from "primevue/progressspinner";
	import { IPhoneObject } from "@/types/models/common/phoneObject.interface";
	import QuotationSteps from "@/components/product/quotationSteps.vue"
	import { cloneDeep } from "lodash";
	import { IQuotationPageViewModel } from "../../types/viewModels/commerce/QuotationPageViewModel";
	import WalletMessage from "@/components/common/WalletMessage.vue";
	import { UrlHelper } from "@/types/helpers/urlHelper";

	interface FormModel {
		firstName: string;
		lastName: string;
		phone: string;
		companyName: string;
		hasCSN: boolean;
		email: string;
		message: string;
		wantsMoreProducts: boolean;
		productMessage: string;
	}
	interface QuotationData {
		firstName: string;
		lastName: string;
		phone: string;
		companyName: string;
		email: string;
		message: string;
		dunsNumber: string;
		isImpersonated: boolean;
	}

	interface AltaresData {
		dunsNumber: string;
		isKnownInCrm: boolean | null;
	}


	@Component({
		components: {
			BaseComponent,
			InputWithValidation,
			AutoComplete,
			ProgressSpinner,
			Checkbox,
			Textarea,
			QuotationSteps,
			WalletMessage
		},
	})
	export default class QuotationRequest extends BaseComponent {
		@Prop({ type: Object as PropType<IQuotationPageViewModel>, required: true, default: {} })
		vm!: IQuotationPageViewModel;

		model: FormModel = {
			firstName: "",
			lastName: "",
			phone: "",
			companyName: "",
			hasCSN: false,
			email: "",
			message: "",
			wantsMoreProducts: false,
			productMessage: "",
		};

		activeStep = 1; // default step 1

		checkboxFieldCSN!: FieldContext;
		checkboxFieldProducts!: FieldContext;
		form!: FormContext<FormModel>;
		companySuggestions: any = [];
		phoneObject!: IPhoneObject;
		altaresData: AltaresData = {
			dunsNumber: "",
			isKnownInCrm: false,
		}

		created(): void {
			if (this.userProfile?.isAuthenticated) {
				const { firstName, lastName, phoneNumber, company: { companyName, dunsNumber }, email } = this.userProfile;
				this.model.firstName = firstName;
				this.model.lastName = lastName;
				this.model.phone = phoneNumber;
				this.model.companyName = companyName;
				this.model.email = email;
				this.altaresData.dunsNumber = dunsNumber;
				this.altaresData.isKnownInCrm = true;
			}

			this.form = useForm({ initialValues: this.model, validationSchema: this.validations });
			this.checkboxFieldCSN = useField(() => "hasCSN", undefined, {
				type: 'checkbox',
				checkedValue: true,
				uncheckedValue: false,
			});

			this.checkboxFieldProducts = useField(() => "wantsMoreProducts", undefined, {
				type: 'checkbox',
				checkedValue: true,
				uncheckedValue: false,
			});
		}

		get validations(): Record<string, unknown> {
			return reactive({
				companyName: { required: true },
				firstName: { required: true },
				lastName: { required: true },
				phone: {
					required: true,
					validPhone: computed(() => this.phoneObject),
				},
				email: { required: true, email: true },
				hasCSN: { required: false },
				message: { required: computed(() => this.form?.values?.hasCSN) },
				wantsMoreProducts: { required: false },
				productMessage: { required: computed(() => this.form?.values?.wantsMoreProducts) }
			});
		}

		get isValid(): boolean {
			return unref(this.form.meta)?.valid;
		}


		get isLoading(): boolean {
			return this.loadingStore.isLoading;
		}

		onPhoneInputValidate(phoneObject: IPhoneObject): void {
			this.phoneObject = phoneObject;
		}


		searchCompanies(query: string): void {
			const countries = ["nl", "be", "de", "ch", "at", "fr", "pt", "es", "it", "gb", "nb"];
			const countryISOAlpha2Code = countries.includes(this.currentLocation) ? this.currentLocation : null;

			this.axios
				.get(`/api/altares/organizations/by-name`, {
					params: { searchTerm: query.trim(), countryISOAlpha2Code: countryISOAlpha2Code },
				})
				.then((res) => {
					this.companySuggestions = res.data;
				})
				.catch((err) => Log.error(err))
				.finally();
		}

		onSuggestionSelection(dunsNumber: string): void {
			this.loadingStore.showSpinner("autofill");

			this.axios
				.get(`/api/altares/organization/by-duns`, {
					params: { dunsNumber: dunsNumber },
				})
				.then((res) => {
					const { name, isKnownInCrm, dunsNumber} = res.data;
					this.altaresData = {
						dunsNumber, isKnownInCrm
					}

					this.form.setTouched({
						companyName: true,
						firstName: true,
						lastName: true,
						phone: true,
						hasCSN: false,
						email: true,
						message: true,
						wantsMoreProducts: false,
						productMessage: true,
					});

					this.form.setFieldValue("companyName", name)
				})
				.catch((err) => Log.error(err))
				.finally(() => {
					setTimeout(() => this.loadingStore.hideSpinner("autofill"), 5000)

				});
		}

		autoFillOptOut() {
			this.altaresData = {
				dunsNumber: "",
				isKnownInCrm: null,
			};
		}

		createMessage(formData: FormModel) {
			let message = `${formData.hasCSN ? formData.message : 'n.v.t.'}
				\n Andere producten: ${formData.wantsMoreProducts ? formData.productMessage : 'n.v.t.'}`;

			return message
		}

		mapToModel(formData: FormModel, altaresData: AltaresData): QuotationData {
			const model = {
				firstName: formData.firstName,
				lastName: formData.lastName,
				phone: formData.phone,
				companyName: formData.companyName,
				email: formData.email,
				message: this.createMessage(formData),
				dunsNumber: altaresData.dunsNumber || "",
				isKnownInCrm: altaresData.isKnownInCrm || "",
				language: this.currentLanguage,
				isImpersonated: this.userProfile?.isImpersonated || false
			}

			return model;
		}

		onSubmit(): void {
			const formData = this.form.values;
			const altaresData = this.altaresData;
			this.loadingStore.showSpinner("loading");
			this.axios
				.post("/api/quote/request", this.mapToModel(formData, altaresData))
				.then(() => {
					this.activeStep = 2;
					this.trackForm();
				})
				.catch((err) => Log.error(err))
				.finally(() => {
					this.loadingStore.hideSpinner("loading");
				});
		}

		goBack(): void {
			UrlHelper.goBack();
		}

		formStateChanged(): FormMeta<FormModel> {
			const { meta, values } = this.form;
			this.model = cloneDeep(values);
			return unref(meta);
		}

		trackForm() {
			const event = new CustomEvent('track-form-submit', {
				detail: {
					form_title: 'Software Quote',
					form_type: 'software'
				}
			});
			dispatchEvent(event);
		}
	}
	</script>

	<style scoped lang="scss">
		.vue-tel-input {
			&:hover {
				border-color: #91004b;
			}
		}

		.sticky-bottom-panel {
			position: sticky;
			bottom: 0;
			left: 0;
			z-index: 101;
			width: 100%;
			background: var(--surface-a);
			box-shadow: 0rem -0.25rem 0.5rem -0.4375rem rgba(0, 0, 0, 0.75);

			.product-summary-icon {
				height: 1.75rem;
				width: 1.75rem;

				@media (min-width: 36rem) {
					height: 2.25rem;
					width: 2.25rem;
				}
			}

			.title {
				font-size: 1.125rem;
				font-weight: 400;
				line-height: 1.5;

				@media (min-width: 36rem) {
					font-size: 1.125rem;
				}
			}

			.product-text-wrapper {
				.title {
					font-size: 0.8rem;

					@media (min-width: 36rem) {
						font-size: 1rem;
					}
				}

				.lead {
					font-size: 1rem;

					@media (min-width: 36rem) {
						font-size: 1.25rem;
					}
				}
			}

			.price-text-wrapper {
				padding-top: 0.5rem;

				@media (min-width: 36rem) {
					padding-top: 1rem;
				}

				.price-text {
					font-size: 1.25rem;
					font-weight: 500;
					line-height: 0.8;
					color: #91004b;
					padding-bottom: 0.25rem;

					@media (min-width: 36rem) {
						font-size: 1.625rem;
						padding-bottom: 0.5rem;
					}
				}

				.wallet-text {
					color: #91004b;
					font-size: 0.8rem;

					@media (min-width: 36rem) {
						font-size: 1rem;
					}
				}
			}
		}

		.login-text {
			.login-chev {
				font-size: 0.75rem;
			}

			margin-bottom: 0.25rem !important;

			@media (min-width: 62rem) {
				margin-bottom: 1rem !important;
			}
		}

		.login-button {
			display: inline-block;
			background: #91004b;
			border-radius: 0.25rem;
			padding: 0.1875rem 1rem;
			color: #fff;
			text-decoration: none;
			margin-bottom: 1.5rem;

			&:hover {
				background: #c91254;
				color: #fff;
				text-decoration: none;
			}

			.icon {
				background: no-repeat center url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikljb25zL2Fycm93SW5DaXJjbGVSaWdodCI+CjxjaXJjbGUgaWQ9IkVsbGlwc2UgMTUiIGN4PSI4IiBjeT0iOCIgcj0iOCIgZmlsbD0id2hpdGUiLz4KPHBhdGggaWQ9IlZlY3RvciAxNCIgZD0iTTQgOEgxMk0xMiA4TDggNE0xMiA4TDggMTIiIHN0cm9rZT0iI0I1MEI1MSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+Cjwvc3ZnPgo=);
				display: inline-block;
				width: 1rem;
				height: 1rem;
				margin-left: 0.625rem;
				float: right;
				margin-top: 0.25rem;
			}

			@media (min-width: 62rem) {
			}
		}

		.message-field {
			margin-top: -2rem;
		}

		.product-summary {
			border: 0.0875rem solid #e5e5e2;
			border-radius: 0.3125rem;
			padding: 1rem;
			width: 100%;

			@media (min-width: 62rem) {
				width: 80%;
				margin: 2rem;
			}

			.product-summary-icon {
				height: 4.1875rem;
				width: 4.1875rem;
			}

			h4 {
				@media (max-width: 36rem) {
					font-size: 1.125rem;
				}
			}

			.product-term,
			.product-detail,
			p,
			span,
			.bottom-text {
				margin: 0;
				color: #6a6a69;
			}

			.product-term {
				font-size: 1.15rem;
				font-weight: 400;
			}

			.product-detail {
				@media (min-width: 36rem) {
					font-size: 1.35rem;
				}

				font-size: 1.15rem;
			}

			span {
				margin: 2% 0% 0% 4%;
				font-size: 0.6875rem;
			}

			.bottom-text {
				font-size: 0.8125rem;
			}
		}

        .wallet-card {
            padding: 1rem;
            margin: 1rem 0;
            width: 100%;
            background-color: #b72a5a;
            border-radius: 0.3125rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background-position: center;
            background-size: cover;

            @media (min-width: 62rem) {
                margin: 2rem;
            }

            @media (min-width: 36rem) {
                width: 80%;
            }
        }

        .wallet-text {
            font-size: 1rem;
            margin-right: 1rem;

            @media (min-width: 36rem) {
                font-size: 1.3rem;
            }
        }
     
	    .wallet-icon {
			width: 2rem;
			height: 2rem;
			float: left;
			margin-right: 1rem;
		}

		.wallet-amount {
			display: inline-block;
			font-size: 1.35rem;
			font-weight: 450;
			-webkit-mask-image: linear-gradient( -75deg, rgba(207, 114, 52, 0.6) 30%, rgba(207, 114, 52) 50%, rgba(207, 114, 52, 0.6) 70% );
			-webkit-mask-size: 200%;
			animation: shine 3s linear infinite;
			color: #ffcc81;

			@media (min-width: 2000px) {
				font-size: 2rem;
			}
		}

		@keyframes shine {
			from {
				-webkit-mask-position: 150%;
			}

			to {
				-webkit-mask-position: -50%;
			}
		}

		.thank-you-container {
			display: flex;
			justify-content: center;
			width: 100%;
		}

		.thank-you-content {
			text-align: center;
			max-width: 43.75rem;
			padding: 1.25rem;
			background: #fff;
		}

		.thank-you-img {
			border-radius: 50%;
		}

	</style>
