<template>
	<div class="row">
		<div v-if="this.maxAutodeskWalletToSpend > 0" class="col-12 mb-2 cashback-container">
			<div class="cashback-title">
				<img v-lazy="'/Static/images/autodesk-coin.png'"
					 alt="autodesk loyalty cashback icon"
					 class="wallet-icon" />
				<div class="wallet-title">
					{{$t("checkout.cashback.title")}}
				</div>
			</div>
			<div class="cashback-block">
				<div class="cashback-block-title">
					{{$t("checkout.cashback.text")}}
				</div>
				<div class="cashback-block-check">
					<Checkbox @change="toggleLoyalty()" inputId="applyLoyalty" v-model="applyLoyalty" :binary="true" />
					<label class="ml-1 mb-0" for="applyLoyalty">
						{{$t("checkout.cashback.checkbox", { amount: formatCurrency(Math.min(this.maxAutodeskWalletToSpend, this.vm.cartClient.subtotalWithoutVat)) })}}
					</label>
				</div>
			</div>
		</div>
		<div class="col-12 mb-2">
			<h3>{{ $t("checkout.titleSelectPaymentMethod") }}</h3>
			<Message v-if="!hasPaymentOptions" :closable="false" severity="warn">
				{{ $t("checkout.message.noPaymentMethodsAvailable") }}
			</Message>
			<DataTable
				v-if="hasPaymentOptions"
				v-model:selection="selectedPaymentMethod"
				responsive-layout="stack"
				selection-mode="single"
				:value="paymentOptions"
				@row-select="onPaymentMethodSelected($event)"
			>
				<Column
					class="text-lg-center"
					selection-mode="single"
					style="width: 5%; padding: 1rem; overflow: hidden"
				/>
				<Column style="width: 20%; overflow: hidden">
					<template #body="item">
						<svg class="mb-1 mr-1 icon-payment-option">
							<use :xlink:href="item.data.icon" />
						</svg>
					</template>
				</Column>
				<Column style="width: 75%">
					<template #body="item">
						<div class="d-flex flex-column p-2">
							<h4 class="mb-0">
								{{ item.data.title }}
								<small class="font-italic">{{ item.data.cost }}</small>
							</h4>
							<p class="lead text-muted">{{ item.data.subtitle }}</p>
							<Dropdown
								v-if="item.data.options?.length > 0"
								v-model="vm.checkoutForm.selectedPaymentProvider"
								option-label="label"
								option-value="value"
								:options="item.data.options"
								:placeholder="$t('checkout.paymentMethod.selectProvider_placeholder')"
								@change="onPaymentMethodOptionSelected(item)"
							/>
						</div>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component } from "vue-facing-decorator";
import { ICheckout } from "@/types/viewModels/commerce/checkoutViewModel";
import { IDropdownItem } from "@/types/models/common/dropdownItem.interface";
import { PaymentMethod } from "@/types/enum/paymentMethod";
import { useCheckoutStore } from "@/store/commerce/checkoutStore";
import Checkbox from "primevue/checkbox";

export interface IPaymentMethodOption {
	id: string;
	paymentMethod: PaymentMethod;
	icon: string;
	title: string;
	subtitle: string;
	cost?: string;
	options?: IDropdownItem[];
}

@Component({ components: { Checkbox	} })
export default class PaymentMethodSelection extends BaseComponent {
	paymentOptions: IPaymentMethodOption[] = [];
	selectedPaymentMethod!: IPaymentMethodOption;
	checkoutStore = useCheckoutStore();

	applyLoyalty = false;
	maxAutodeskWalletToSpend = 0;

	get vm(): ICheckout {
		return this.checkoutStore.vm as ICheckout;
	}

	get hasPaymentOptions(): boolean {
		return !!this.paymentOptions?.length;
	}

	created(): void {
		this.paymentOptions = this.vm.availablePaymentMethods.map(({ key, value }) => ({
			id: key,
			paymentMethod: value,
			title: this.$t(`enum.paymentMethod.${value}`),
			subtitle: this.getSubtitleForPaymentMethod(value),
			icon: this.getIconForPaymentMethod(value),
			cost: this.getCostForPaymentMethod(value),
			options: this.getOptionsForPaymentMethod(value),
		}));
		this.selectedPaymentMethod = this.paymentOptions.find(
			(x) => x.id === this.vm.checkoutForm.paymentMethod
		) as IPaymentMethodOption;

		// Max to spend, is the lowest of the two: [your current autodeskloyaltycashback and max you can spend on this order (= subtotalWithoutVat, since we cannot spend T3 on VAT)]
		this.maxAutodeskWalletToSpend = Math.min(this.vm?.mainLayout?.currentUser?.company?.totalAutodeskSaved, this.vm.cartClient.subtotalWithoutVat)		

		// preset checkbox applyLoyalty boolean
		this.applyLoyalty = this.vm.checkoutForm.applyAutodeskWallet;
	}

	toggleLoyalty() {
		this.checkoutStore.toggleApplyAutodeskWallet(this.applyLoyalty, this.maxAutodeskWalletToSpend);
	}

	onPaymentMethodSelected({ data }: { data: IPaymentMethodOption }): void {
		// Make sure the payment method stays selected (on mobile viewport it will deselect)
		setTimeout(() => (this.selectedPaymentMethod = data), 10);
		this.checkoutStore.setPaymentMethod(data.id);
	}

	onPaymentMethodOptionSelected({ data }: { data: IPaymentMethodOption }): void {
		data.icon = this.getIconForPaymentMethod(data.paymentMethod);
		this.checkoutStore.setPaymentMethod(data.id);
		this.checkoutStore.setSelectedPaymentProvider(this.vm.checkoutForm.selectedPaymentProvider);
	}

	isPaymentOptionValid(): boolean {
		const { selectedPaymentProvider, selectedPaymentMethod } = this.vm.checkoutForm;
		const paymentMethodOption = this.paymentOptions.find((x) => x.paymentMethod === selectedPaymentMethod);
		if (!paymentMethodOption) return false;
		if (paymentMethodOption?.options?.length) {
			return paymentMethodOption.options.findIndex((x) => x.value === selectedPaymentProvider) < 0;
		}
		return true;
	}

	private getIconForPaymentMethod(paymentMethod: PaymentMethod): string {
		switch (paymentMethod) {
			case PaymentMethod.ideal:
				return `#payment-ideal`;
			case PaymentMethod.bancontactMrCash:
				return `#payment-bancontactmrcash`;
			case PaymentMethod.paypal:
				return `#payment-paypal`;
			case PaymentMethod.creditcard:
				return this.vm.creditCardMethods.findIndex(
					(x) => x.key === this.vm.checkoutForm.selectedPaymentProvider
				) > -1
					? `#payment-${this.vm.checkoutForm.selectedPaymentProvider}`
					: `#payment-creditcard`;
			case PaymentMethod.onAccount:
				return `#payment-onaccount`;
			case PaymentMethod.sofort:
				return `#payment-sofort`;
		}
	}

	private getSubtitleForPaymentMethod(paymentMethod: PaymentMethod): string {
		switch (paymentMethod) {
			case PaymentMethod.sofort:
				return this.$t(`checkout.paymentMethod.subtitle_sofort`);
			case PaymentMethod.ideal:
				return this.$t(`checkout.paymentMethod.subtitle_ideal`);
			case PaymentMethod.bancontactMrCash:
				return this.$t(`checkout.paymentMethod.subtitle_mrcash`);
			case PaymentMethod.paypal:
				return this.$t(`checkout.paymentMethod.subtitle_paypal`);
			case PaymentMethod.creditcard:
				return this.$t(`checkout.paymentMethod.subtitle_creditcard`, [
					this.getOptionsForPaymentMethod(paymentMethod)
						.map((x) => x.label)
						.join(", "),
				]);
			case PaymentMethod.onAccount:
				return this.$t(
					`checkout.paymentMethod.paymentCondition.${this.vm.checkoutForm.invoicePaymentConditionCode}`
				);
		}
	}

	private getCostForPaymentMethod(paymentMethod: PaymentMethod): string {
		switch (paymentMethod) {
			case PaymentMethod.sofort:
			case PaymentMethod.ideal:
			case PaymentMethod.bancontactMrCash:
			case PaymentMethod.paypal:
			case PaymentMethod.onAccount:
				return this.$t(`common.free`);
			case PaymentMethod.creditcard:
				return `+${this.$t("common.priceSummary.creditCardSurcharge", [this.vm.creditCardSurtaxPercentage])}`;
		}
	}

	private getOptionsForPaymentMethod(paymentMethod: PaymentMethod): IDropdownItem[] {
		switch (paymentMethod) {
			case PaymentMethod.bancontactMrCash:
			case PaymentMethod.sofort:
			case PaymentMethod.paypal:
			case PaymentMethod.onAccount:
				return [];
			case PaymentMethod.ideal:
				return this.vm.idealIssuers.map((x) => ({ value: x.key, label: x.value }));
			case PaymentMethod.creditcard:
				return this.vm.creditCardMethods.map((x) => ({ value: x.key, label: x.value }));
		}
	}
}
</script>

<style lang="scss" scoped>
.icon-payment-option {
	transform: scale(0.65);
	display: block;
	width: 100%;
}

.text-payment-option {
	flex: 3;
}

.cashback-title {
	display: flex;
	padding-bottom: 2rem;
}

.wallet-icon {
    width: 3rem;
    height: 3rem;    
    margin-right: 1rem;
}

.wallet-title {
    font-size: 2rem;
    font-weight: 500;
	line-height: 42px;
}

.cashback-block {
	background-color: #FEF4F7;	
	display: flex;
	flex-direction: column;
	min-height: 150px;
	padding: 2rem;
}

.cashback-block-title {
	font-size: 1.5rem;
	font-weight: 450;
	line-height: 26px;
	padding-bottom: 1rem;
}

.cashback-block-check label {
	font-size: 1rem;
	font-weight: 400;
	line-height: 26px;
}

::v-deep(.p-datatable) {
	.p-datatable-tbody > tr > td {
		padding: 0 0.25rem;
	}
}
</style>
