import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46a3b3e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 mb-2 cashback-container"
}
const _hoisted_3 = { class: "cashback-title" }
const _hoisted_4 = {
  alt: "autodesk loyalty cashback icon",
  class: "wallet-icon"
}
const _hoisted_5 = { class: "wallet-title" }
const _hoisted_6 = { class: "cashback-block" }
const _hoisted_7 = { class: "cashback-block-title" }
const _hoisted_8 = { class: "cashback-block-check" }
const _hoisted_9 = {
  class: "ml-1 mb-0",
  for: "applyLoyalty"
}
const _hoisted_10 = { class: "col-12 mb-2" }
const _hoisted_11 = { class: "mb-1 mr-1 icon-payment-option" }
const _hoisted_12 = ["xlink:href"]
const _hoisted_13 = { class: "d-flex flex-column p-2" }
const _hoisted_14 = { class: "mb-0" }
const _hoisted_15 = { class: "font-italic" }
const _hoisted_16 = { class: "lead text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.maxAutodeskWalletToSpend > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [
              [_directive_lazy, '/Static/images/autodesk-coin.png']
            ]),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("checkout.cashback.title")), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("checkout.cashback.text")), 1),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Checkbox, {
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleLoyalty())),
                inputId: "applyLoyalty",
                modelValue: _ctx.applyLoyalty,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.applyLoyalty) = $event)),
                binary: true
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("checkout.cashback.checkbox", { amount: _ctx.formatCurrency(Math.min(this.maxAutodeskWalletToSpend, this.vm.cartClient.subtotalWithoutVat)) })), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t("checkout.titleSelectPaymentMethod")), 1),
      (!_ctx.hasPaymentOptions)
        ? (_openBlock(), _createBlock(_component_Message, {
            key: 0,
            closable: false,
            severity: "warn"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("checkout.message.noPaymentMethodsAvailable")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.hasPaymentOptions)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 1,
            selection: _ctx.selectedPaymentMethod,
            "onUpdate:selection": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedPaymentMethod) = $event)),
            "responsive-layout": "stack",
            "selection-mode": "single",
            value: _ctx.paymentOptions,
            onRowSelect: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onPaymentMethodSelected($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                class: "text-lg-center",
                "selection-mode": "single",
                style: {"width":"5%","padding":"1rem","overflow":"hidden"}
              }),
              _createVNode(_component_Column, { style: {"width":"20%","overflow":"hidden"} }, {
                body: _withCtx((item) => [
                  (_openBlock(), _createElementBlock("svg", _hoisted_11, [
                    _createElementVNode("use", {
                      "xlink:href": item.data.icon
                    }, null, 8, _hoisted_12)
                  ]))
                ]),
                _: 1
              }),
              _createVNode(_component_Column, { style: {"width":"75%"} }, {
                body: _withCtx((item) => [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("h4", _hoisted_14, [
                      _createTextVNode(_toDisplayString(item.data.title) + " ", 1),
                      _createElementVNode("small", _hoisted_15, _toDisplayString(item.data.cost), 1)
                    ]),
                    _createElementVNode("p", _hoisted_16, _toDisplayString(item.data.subtitle), 1),
                    (item.data.options?.length > 0)
                      ? (_openBlock(), _createBlock(_component_Dropdown, {
                          key: 0,
                          modelValue: _ctx.vm.checkoutForm.selectedPaymentProvider,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.vm.checkoutForm.selectedPaymentProvider) = $event)),
                          "option-label": "label",
                          "option-value": "value",
                          options: item.data.options,
                          placeholder: _ctx.$t('checkout.paymentMethod.selectProvider_placeholder'),
                          onChange: ($event: any) => (_ctx.onPaymentMethodOptionSelected(item))
                        }, null, 8, ["modelValue", "options", "placeholder", "onChange"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["selection", "value"]))
        : _createCommentVNode("", true)
    ])
  ]))
}